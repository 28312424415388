import axios from "axios";

export default axios.create({
  
  
  baseURL: process.env.VUE_APP_PRODUCTION == 'DEV' ?  "http://localhost:3000/api" : "https://eykalutaapp-bb4b0e00bba7.herokuapp.com/api",
  
  headers: {
    "Content-type": "application/json",
  },
});
